import Money, { SerializedMoney } from "@/models/money";

export interface SerializedUserReferral {
    clicks_count: number;
    referral_code: string;
    payment_phone_number: string;
    orders: Array<SerializedReferralOrder> | null;
}

interface SerializedReferralOrder {
    order_id: string;
    status: string;
    created_at: string;
    payment_phone_number: string;
    amount_earned: SerializedMoney;
}

class ReferralOrder {
    private readonly _orderId: string;
    private readonly _amountEarned: Money;
    private readonly _createdAt: Date;
    private readonly _paymentPhoneNumber: string;
    constructor(payload: SerializedReferralOrder) {
        this._orderId = payload.order_id;
        this._createdAt = new Date(payload.created_at);
        this._paymentPhoneNumber = payload.payment_phone_number;
        this._amountEarned = new Money(payload.amount_earned);
    }

    get createdAt(): Date {
        return this._createdAt;
    }

    get paymentPhoneNumber(): string {
        return this._paymentPhoneNumber;
    }

    get orderId(): string {
        return this._orderId;
    }

    get amountEarned(): Money {
        return this._amountEarned;
    }
}

export default class UserReferral {
    private readonly _orders: Array<ReferralOrder>;
    private readonly _clicksCount: number;
    private readonly _code: string;
    private readonly _phoneNumber: string;

    constructor(referral: SerializedUserReferral) {
        this._orders =
            referral.orders === null
                ? []
                : referral.orders.map((order) => new ReferralOrder(order));
        this._clicksCount = referral.clicks_count;
        this._phoneNumber = referral.payment_phone_number;
        this._code = referral.referral_code;
    }

    get clicksCount(): number {
        return this._clicksCount;
    }

    get orders(): Array<ReferralOrder> {
        return this._orders;
    }

    get code(): string {
        return this._code;
    }

    get totalAmount(): number {
        return this._orders.reduce((sum, order) => {
            return sum + order.amountEarned.amount;
        }, 0);
    }

    get orderCount(): number {
        return this._orders.length;
    }

    get phoneNumber(): string {
        return this._phoneNumber;
    }
}
