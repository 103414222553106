export interface SerializedExchangeSettings {
    mtn_withdrawal_fees: number;
    orange_withdrawal_fees: number;
    mtn_deposit_fees: number;
    orange_deposit_fees: number;
}

class SettingsExchange {
    private readonly _mtnWithdrawalFees: number;
    private readonly _orangeWithdrawalFees: number;
    private readonly _mtnDepositFees: number;
    private readonly _orangeDepositFees: number;

    constructor(settings: SerializedExchangeSettings) {
        this._mtnWithdrawalFees = settings.mtn_withdrawal_fees;
        this._orangeWithdrawalFees = settings.orange_withdrawal_fees;
        this._mtnDepositFees = settings.mtn_deposit_fees;
        this._orangeDepositFees = settings.orange_deposit_fees;
    }

    get mtnWithdrawalFees(): number {
        return this._mtnWithdrawalFees;
    }

    get orangeWithdrawalFees(): number {
        return this._orangeWithdrawalFees;
    }

    get mtnDepositFees(): number {
        return this._mtnDepositFees;
    }

    get orangeDepositFees(): number {
        return this._orangeDepositFees;
    }
}

export interface SerializedSettings {
    coinpayments_merchant_id: string;
    usd_xaf_rate: number;
    exchange: SerializedExchangeSettings;
}

export default class Settings {
    private readonly _coinpaymentsMerchantId: string;
    private readonly _usdXafRate: number;
    private readonly _exchange: SettingsExchange;

    constructor(settings: SerializedSettings) {
        this._coinpaymentsMerchantId = settings.coinpayments_merchant_id;
        this._usdXafRate = settings.usd_xaf_rate;
        this._exchange = new SettingsExchange(settings.exchange);
    }

    get usdXafRate(): number {
        return this._usdXafRate;
    }

    get exchange(): SettingsExchange {
        return this._exchange;
    }

    get coinpaymentsMerchantId(): string {
        return this._coinpaymentsMerchantId;
    }
}
