import { AxiosResponse } from "axios";
import Axios from "./axios";

class PhoneNumber {
    private axios: typeof Axios;

    constructor(axios: typeof Axios) {
        this.axios = axios;
    }

    store(phoneNumber: string, captcha: string): Promise<AxiosResponse> {
        return this.axios.post(`/phone-number/`, {
            // eslint-disable-next-line @typescript-eslint/camelcase
            recipient_phone_number: phoneNumber,
            captcha,
        });
    }
}

export default new PhoneNumber(Axios);
