export interface SerializedUserProfileSettings {
    name: string;
    email: string;
    address: string;
    payment_method: string;
    payment_phone_number: string;
}

export class UserProfileSettings {
    private readonly _name: string;
    private readonly _email: string;
    private readonly _address: string;
    private readonly _paymentMethod: string;
    private readonly _paymentPhoneNumber: string;

    constructor(input: SerializedUserProfileSettings) {
        this._email = input.email;
        this._name = input.name;
        this._address = input.address;
        this._paymentMethod = input.payment_method;
        this._paymentPhoneNumber = input.payment_phone_number;
    }
    get name(): string {
        return this._name;
    }

    get email(): string {
        return this._email;
    }

    get address(): string {
        return this._address;
    }

    get paymentMethod(): string {
        return this._paymentMethod;
    }

    get paymentPhoneNumber(): string {
        return this._paymentPhoneNumber;
    }
}

export interface SerializedUserDstvSettings {
    dstv_package_id: string;
    smartcard_number: string;
}

export class UserDStvSettings {
    private readonly _dstvPackageId: string;
    private readonly _smartcardNumber: string;
    constructor(input: SerializedUserDstvSettings) {
        this._dstvPackageId = input.dstv_package_id;
        this._smartcardNumber = input.smartcard_number;
    }
    get smartcardNumber(): string {
        return this._smartcardNumber;
    }

    get dstvPackageId(): string {
        return this._dstvPackageId;
    }
}

export interface SerializedUserAirtimeSettings {
    recipient_phone_number: string;
    phone_network: string;
    amount: number;
}

export class UserAirtimeSettings {
    private readonly _recipientPhoneNumber: string;
    private readonly _phoneNetwork: string;
    private readonly _amount: number;

    constructor(input: SerializedUserAirtimeSettings) {
        this._recipientPhoneNumber = input.recipient_phone_number;
        this._amount = input.amount;
        this._phoneNetwork = input.phone_network;
    }

    get phoneNetwork(): string {
        return this._phoneNetwork;
    }

    get recipientPhoneNumber(): string {
        return this._recipientPhoneNumber;
    }

    get amount(): number {
        return this._amount;
    }
}

export interface SerializedUserExchangeSettings {
    recipient_phone_number: string;
    phone_network: string;
    amount: number;
}

export class UserExchangeSettings {
    private readonly _recipientPhoneNumber: string;
    private readonly _phoneNetwork: string;
    private readonly _amount: number;

    constructor(input: SerializedUserExchangeSettings) {
        this._recipientPhoneNumber = input.recipient_phone_number;
        this._amount = input.amount;
        this._phoneNetwork = input.phone_network;
    }

    get phoneNetwork(): string {
        return this._phoneNetwork;
    }

    get recipientPhoneNumber(): string {
        return this._recipientPhoneNumber;
    }

    get amount(): number {
        return this._amount;
    }
}

export interface SerializedUserNotificationSettings {
    newsletter_email: boolean;
    dstv_reminder: boolean;
}

export class UserNotificationSettings {
    private readonly _newsletterEmail: boolean;
    private readonly _dstvReminder: boolean;
    constructor(input: SerializedUserNotificationSettings) {
        this._newsletterEmail = input.newsletter_email;
        this._dstvReminder = input.dstv_reminder;
    }

    get newsletterEmail(): boolean {
        return this._newsletterEmail;
    }

    get dstvReminder(): boolean {
        return this._dstvReminder;
    }
}

export interface SerializedUserEneoSettings {
    meter_type: string;
    meter_number: string | null;
    amount: number;
}

export class UserEneoSettings {
    private readonly _meterType: string | null;
    private readonly _meterNumber: string | null;
    private readonly _amount: number | null;

    constructor(input: SerializedUserEneoSettings) {
        this._amount = input.amount;
        this._meterNumber = input.meter_number;
        this._meterType = input.meter_type;
    }

    get meterType(): string | null {
        return this._meterType;
    }

    get meterNumber(): string | null {
        return this._meterNumber;
    }

    get amount(): number | null {
        return this._amount;
    }
}

export interface SerializedCamwaterSettings {
    contract_number: string;
}

export class UserCamwaterSettings {
    private readonly _contractNumber: string;

    constructor(input: SerializedCamwaterSettings) {
        this._contractNumber = input.contract_number;
    }

    get contractNumber(): string {
        return this._contractNumber;
    }
}

export interface SerializedCanalplusSettings {
    card_number: string;
    canalplus_package_id: string;
}

export class UserCanalplusSettings {
    private readonly _cardNumber: string;
    private readonly _canalplusPackageId: string;

    constructor(input: SerializedCanalplusSettings) {
        this._cardNumber = input.card_number;
        this._canalplusPackageId = input.canalplus_package_id;
    }

    get cardNumber(): string {
        return this._cardNumber;
    }

    get canalplusPackageId(): string {
        return this._canalplusPackageId;
    }
}
