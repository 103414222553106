export interface SerializedPhoneNetwork {
    id: string;
    name: string;
    logo: string;
    user: SerializedPhoneNetworkUser | null;
}

export interface SerializedPhoneNetworkUser {
    name: string;
}

class PhoneNetworkUser {
    private readonly _name: string;

    constructor(user: SerializedPhoneNetworkUser) {
        this._name = user.name;
    }

    get name(): string {
        return this._name;
    }
}

export default class PhoneNetwork {
    private readonly _id: string;
    private readonly _name: string;
    private readonly _logo: string;
    private readonly _user: PhoneNetworkUser | null;

    constructor(network: SerializedPhoneNetwork) {
        this._id = network.id;
        this._name = network.name;
        this._logo = network.logo;
        this._user = network.user ? new PhoneNetworkUser(network.user) : null;
    }

    get id(): string {
        return this._id;
    }

    get name(): string {
        return this._name;
    }

    get user(): PhoneNetworkUser | null {
        return this._user;
    }

    get logo(): string {
        return this._logo;
    }
}
