export interface SerializedMoney {
    amount: number;
    currency: string;
}

export default class Money {
    private readonly _amount: number;
    private readonly _currency: string;
    constructor(payload: SerializedMoney) {
        this._amount = payload.amount;
        this._currency = payload.currency;
    }

    get amount(): number {
        return this._amount;
    }

    get currency(): string {
        return this._currency;
    }
}
